.container {
    cursor: grab;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* justify-content: center; */
    margin-top: 15px;
    background: white;
    border: 1px solid #d0d5dd;
    /* Shadow/xs */

    /* box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); */
    border-radius: 6px;

    padding-left: 15px;
    transform: translate(0, 0);
    align-self: center;
    /* margin-left: -10px; */
    position: relative;
    user-select: none;

    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
        0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 125% */

    color: #212121;
    margin-left: 10px;
}

.helperContainer {
    position: absolute;
    top: -100%;
    justify-self: center;
    font-size: 10px;
    width: 100%;
    background-color: black;
    color: white;
    border-radius: 4px;
    text-align: center;
    padding: 3px 3px 3px 3px;
    left: 0;
    /* width: fit-content; */
    /* white-space: nowrap; */
}