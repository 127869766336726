html {
    box-sizing: border-box;
    height: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

#root {
    min-width: 100vw;
    /* min-height: 100vh; */
    height: 100%;
    display: flex;
    /* overflow: scroll; */
}

:root {
    --viewport-height: 100%;
    --main-color: #0076cc;
}

body {
    margin: 0;
    min-width: 100vw;
    height: 100%;
    display: flex;
    font-family: 'PlusJakartaSans';
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* 
h1: 26,
h2: 24,
h3: 20,
h4: 18,
h5: 16,
h6: 14
*/

li {
    all: unset;
}

h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    /* line-height: 35px; */
}

h2 {
    all: unset;

    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
}

h4 {
    all: unset;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
}

h5 {
    all: unset;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 17px; */
}

h6 {
    all: unset;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* line-height: 16px; */
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: normal;
    src: url('./assets/fonts/Inter-VariableFont.ttf') format('truetype');
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: italic;
    src: url('./assets/fonts/Inter-VariableFont.ttf') format('truetype');
}